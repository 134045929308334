import React from 'react'
import styled from 'styled-components'

const HomePageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`

const Title = styled.h1`
  font-size: 48px;
  margin-bottom: 16px;
`

const Subtitle = styled.h2`
  font-size: 24px;
  margin-bottom: 32px;
`

const Button = styled.button`
  padding: 12px 24px;
  font-size: 18px;
  border-radius: 8px;
  border: none;
  background-color: #0077cc;
  color: #fff;
  cursor: pointer;

  &:hover {
    background-color: #0066aa;
  }
`

const HomePage: React.FC = () => {
  return (
    <HomePageContainer>
      <Title>Welcome to My Website</Title>
      <Subtitle>Learn more about what we do</Subtitle>
      <Button>Explore</Button>
      <>{console.log('HomePage', process.env.REACT_APP_NODE_ENV)}</>
    </HomePageContainer>
  )
}

export default HomePage

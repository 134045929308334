import React from 'react'
import styled from 'styled-components'

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`

const Title = styled.h1`
  font-size: 48px;
  margin-bottom: 16px;
`

const Subtitle = styled.h2`
  font-size: 24px;
  margin-bottom: 32px;
`

const Error404: React.FC = () => {
  return (
    <ErrorContainer>
      <Title>404</Title>
      <Subtitle>Page not found</Subtitle>
    </ErrorContainer>
  )
}

export default Error404
